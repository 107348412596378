import React, { useEffect, useState } from 'react'
import './App.scss'
import {
	getAuth,
	GoogleAuthProvider,
	FacebookAuthProvider,
	OAuthProvider,
	getRedirectResult,
	signInWithRedirect,
} from 'firebase/auth'
import { initializeApp } from 'firebase/app'
import logo from '../assets/logo.svg'

const link = 'https://auth.aftershoot.co'
const loading = 'Loading...'
const initializing = 'Initializing...'
const redirecting = 'Redirecting...'
const authenticating = 'Authenticating...'
const successful = 'Successful. Please close the window and return to the app.'

initializeApp({
	apiKey: 'AIzaSyBRSw3neiRzAmz-QYo9I3wqr9duKEweGdc',
	authDomain: 'aftershoot-co.firebaseapp.com',
	databaseURL: 'https://aftershoot-co.firebaseio.com',
	projectId: 'aftershoot-co',
	storageBucket: 'aftershoot-co.appspot.com',
	messagingSenderId: '953041219166',
	appId: '1:953041219166:web:627adc0e0fc1b336ddcecf',
	measurementId: 'G-WX4GH99F4Z',
})

const App = () => {
	const [text, setText] = useState(loading)
	const auth = getAuth()

	const componentDidMount = async () => {
		const result = await getRedirectResult(auth)
		window.result = result
		setText(initializing)
		setTimeout(async () => {
			const params = new URLSearchParams(window.location.search)

			const code = params.get('ot-auth-code')
			const provider = params.get('provider')

			if (!result?.user) {
				switch (provider) {
					case 'google':
						signInWithRedirect(auth, new GoogleAuthProvider())
						break
					case 'apple':
						signInWithRedirect(auth, new OAuthProvider('apple.com'))
						break
					case 'facebook':
						signInWithRedirect(auth, new FacebookAuthProvider())
						break
					default:
						signInWithRedirect(auth, new GoogleAuthProvider())
						break
				}
				setText(redirecting)
			} else {
				setText(authenticating)
				setTimeout(async () => {
					if (!result) {
						return
					}

					const token = await result.user.getIdToken()

					try {
						let response = await fetch(
							`${link}/create-auth-token?ot-auth-code=${code}&id-token=${token}`
						)
						response = await response.json()
						if (params.get('pay') === 'true') {
							localStorage.setItem('auth', response.jwt_token)
							localStorage.setItem('uid', response.uid)
							setText('Redirecting to Payment')
						} else {
							setText(successful)
						}
					} catch (err) {
						console.log(err)
					}
				}, 1500)
			}
		}, 1000)
	}

	useEffect(() => {
		componentDidMount()
	}, [])

	return (
		<div className='App'>
			<div className='logo'>
				<img src={logo} alt='AfterShoot Login' />
				<div className='welcome'>Welcome to AfterShoot</div>
				{[loading, initializing, redirecting].includes(text) && (
					<div className='connect'>Connecting to Aftershoot Auth</div>
				)}
				<div className='status'>{text}</div>
				{text !== successful && (
					<div className='slider'>
						<div className='line'></div>
						<div className='subline inc'></div>
						<div className='subline dec'></div>
					</div>
				)}
			</div>
		</div>
	)
}

export default App
